<template>
  <div v-loading="loaders.resource" class="p-4">
    <h1 class="py-3">Education Module</h1>
    <div>
      <url-card
        ref="urlCardForm"
        :url="education.url"
        :status="education.status"
        :url-required="true">
      </url-card>
    </div>
    <el-button type="primary" class="px-5 btn-primary-action font-weight-bold" @click="updateEducationData">Update</el-button>
  </div>
</template>

<script>
import UrlCard from "@/views/Components/ContentSection/UrlCard"
export default {
  name: "Education",
  components: {
    UrlCard
  },
  data() {
    return {
      loaders: {
        resource: false
      },
      education: {
        url: '',
        status: '1'
      },
    }
  },
  created() {
    this.fetchEducationData()
  },
  methods: {

    /**
     * Fetch the Education Data
     */
    async fetchEducationData() {
      let vm = this
      vm.loaders.resource = true
      try {
        const response = await vm.$store.dispatch('_fetchEducationWebsite')
        if (response.data.data) vm.education = response.data.data
      }
      catch (error) {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
        vm.$notify.error({
          title: 'Education',
          message: message
        })
      }
      vm.loaders.resource = false
    },

    /**
     * Update Education data.
     */
    async updateEducationData() {
      if (this.$refs.urlCardForm.formValidate()) {
        let vm = this
        vm.loaders.resource = true
        const formData = this.$refs.urlCardForm.form
        try {
          await vm.$store.dispatch('_updateEducationWebsite', formData)
          vm.$notify.success({
            title: 'Education Module',
            message: 'Education Updated Successfully. Always check to see how your changes look in the Baritastic app.'
          })
          await vm.fetchEducationData()
        }
        catch (error) {
          let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Education',
            message: message
          })
        }
        vm.loaders.resource = false
      }
    },
  }
}
</script>

<style scoped>

</style>
